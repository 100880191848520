import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { CreateExperimentDrawer } from './components/CreateExperimentDrawer/CreateExperimentDrawer'
import { ExperimentsTable } from './components/ExperimentsTable'

export const ExperimentsView: React.FC = () => {
  const drawerDisclosure = useDisclosure()

  return (
    <Box>
      <Box px={6} py={2} borderBottom="1px solid" borderColor="gray.200">
        <Typography
          color="gray.900"
          fontSize="md"
          fontWeight={500}
          lineHeight={6}
        >
          Experiments
        </Typography>
      </Box>

      <Flex px={4} py={2} justifyContent="flex-end">
        <Button onClick={drawerDisclosure.onOpen} size="sm">
          New experiment
        </Button>
        <CreateExperimentDrawer
          isOpen={drawerDisclosure.isOpen}
          onClose={drawerDisclosure.onClose}
        />
      </Flex>

      <ExperimentsTable />
    </Box>
  )
}
