import { Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import {
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfYear,
  format,
  startOfYear,
  subMonths,
} from 'date-fns'
import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

type Props = ReactDatePickerCustomHeaderProps & {
  minDate: Date
  maxDate: Date
}

const CustomHeader = ({
  minDate,
  maxDate,
  monthDate,
  customHeaderCount,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseMonth,
  increaseMonth,
  changeMonth,
  changeYear,
}: Props) => {
  const formattedMonth = format(monthDate, 'MMMM')
  const formattedYear = format(monthDate, 'yyyy')
  const isSecondCalendar = customHeaderCount === 1

  const months = eachMonthOfInterval({
    start: startOfYear(monthDate),
    end: endOfYear(monthDate),
  })

  const yearOptions = eachYearOfInterval({
    start: minDate,
    end: maxDate,
  }).map((date) => {
    const formattedYear = format(date, 'yyyy')

    return {
      id: Number(formattedYear),
      name: formattedYear,
    }
  })

  const monthOptions = months.map((date, index) => ({
    id: index,
    name: format(date, 'MMMM'),
    date,
    disabled:
      subMonths(endOfMonth(date), isSecondCalendar ? 1 : 0) < minDate ||
      date > maxDate,
  }))

  const selectedMonth = monthOptions.find(
    (option) => option.name === formattedMonth,
  )?.id

  const selectedYear = yearOptions.find(
    (option) => option.name === formattedYear,
  )?.id

  return (
    <Flex justifyContent="space-between" alignItems="center" mb={4}>
      <ButtonIcon
        isDisabled={prevMonthButtonDisabled}
        title="Previous month"
        name="ChevronLeftIcon"
        variant="ghost"
        size="sm"
        onClick={decreaseMonth}
      />
      <Dropdown
        matchWidth={false}
        size="sm"
        variant="ghost"
        options={monthOptions}
        value={selectedMonth}
        containerProps={{ minWidth: '130px' }}
        buttonProps={{ colorScheme: 'primary' }}
        callback={(value) => changeMonth(value.id - customHeaderCount)}
        renderInPortal={false}
      />
      <Dropdown
        matchWidth={false}
        size="sm"
        variant="ghost"
        options={yearOptions}
        value={selectedYear}
        placeholder={formattedYear}
        containerProps={{ minWidth: '90px' }}
        buttonProps={{ colorScheme: 'primary' }}
        callback={(value) => {
          if (isSecondCalendar && selectedMonth === 0) {
            changeYear(value.id - 1)

            return
          }

          changeYear(value.id)
        }}
        renderInPortal={false}
      />
      <ButtonIcon
        isDisabled={nextMonthButtonDisabled}
        title="Next month"
        name="ChevronRightIcon"
        variant="ghost"
        size="sm"
        onClick={increaseMonth}
      />
    </Flex>
  )
}

export default CustomHeader
