import { useToast } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons/ButtonIcon'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type FC } from 'react'
import { type ProductLabel } from '../graphql/fragments'
import { useEditProductLabel } from '../graphql/useEditProductLabel'
import {
  ProductLabelPicker,
  type UpdatedProductLabel,
} from './ProductLabelPicker'

interface Props {
  currentLabel: ProductLabel | undefined
}

export const EditProductLabelModal: FC<Props> = ({ currentLabel }) => {
  const editProductLabel = useEditProductLabel()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const onEditLabel = (label: UpdatedProductLabel) => {
    if (!label.id) return

    editProductLabel({
      variables: {
        id: label.id,
        params: {
          name: label.name,
          iconColor: label.iconColor,
          iconName: label.iconName,
        },
      },
      optimisticResponse: {
        editProductLabel: {
          id: label.id,
          name: label.name,
          iconColor: label.iconColor,
          iconName: label.iconName,
          __typename: 'ProductLabel',
        },
      },
      onCompleted: (data) => {
        trackEvent({
          eventName: 'Segmentations Product Label Edited',
          eventProperties: {
            name: data.editProductLabel.name,
            iconName: data.editProductLabel.iconName,
            iconColor: data.editProductLabel.iconColor,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not edit product label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <ProductLabelPicker
      currentLabel={currentLabel}
      modalButton={
        <ButtonIcon size="sm" variant="ghost" title="Edit" name="EditIcon" />
      }
      isEditMode
      onApply={onEditLabel}
    />
  )
}
