import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import type React from 'react'
import { type ColorType } from 'ui/theme/colors'
import { type ExperimentStatus } from '../graphql/fragments'

interface StatusTagProps {
  status: ExperimentStatus
  size?: 'small' | 'medium' | 'large'
  color?: ColorType
}

const iconMapping: Record<ExperimentStatus, React.ReactNode> = {
  configuring: <Icon name="LoadingCircleIcon" size="small" color="blue.500" />,
  draft: <Icon name="CircleDottedIcon" size="small" color="gray.500" />,
  upcoming: <Icon name="CircleIcon" size="small" color="yellow.500" />,
  running: <Icon name="ProgressCircleIcon" size="small" color="purple.500" />,
  completed: <Icon name="CheckCircleIcon" size="small" color="cyan.500" />,
  finalized: (
    <Icon name="CheckCircleSolidIcon" size="small" color="magenta.500" />
  ),
  failed: <Icon name="ExclamationCircleIcon" size="small" color="red.500" />,
}

const labelMapping: Record<ExperimentStatus, string> = {
  configuring: 'Configuring',
  draft: 'Draft',
  upcoming: 'Upcoming',
  running: 'Running',
  completed: 'Completed',
  finalized: 'Final results',
  failed: 'Failed',
}

export const StatusTag: React.FC<StatusTagProps> = ({
  status,
  color,
  size,
}) => {
  return (
    <Flex alignItems="center" gap={2}>
      {iconMapping[status]}
      <Typography
        color={color || 'gray.800'}
        fontSize={size === 'large' ? 'md' : size === 'medium' ? 'sm' : 'xs'}
        lineHeight={size === 'large' ? 5 : 4}
        fontWeight={size === 'large' ? 500 : 400}
      >
        {labelMapping[status]}
      </Typography>
    </Flex>
  )
}
