import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import { type DateRange } from 'constants/types'
import { FilterOperator } from 'components/Filters/types'
import { ChartSortOrder } from 'graphql/reports/types'
import { merchantInfoAtom } from 'graphql/useMerchantInfo'
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { type ChartTypeId, chartTypes } from 'utils/chart/chartTypes'
import { z } from 'zod'
import {
  GLOBAL_FILTER,
  GlobalFilterSchema,
  globalFilters,
} from '../dashboards/globalFilters'
import { inventoryDashboard } from '../dashboards/inventory'
import { marketingDashboard } from '../dashboards/marketing'
import { overviewDashboard, overwriteDashboards } from '../dashboards/overview'
import {
  getDefaultCompareDynamicDate,
  getDefaultDynamicDate,
} from '../utils/defaultDateState'

const DASHBOARD_STATE_KEY = 'dema-dashboard-state'

const defaultDate = getDefaultDynamicDate()
const defaultCompare = getDefaultCompareDynamicDate(defaultDate.value)

// Dates can not be stores as "dates" in local storage, they are always strings
// so we convert them to dates here
const DateValueRange = z.union([
  z.date().or(z.string().transform((date) => new Date(date))),
  z.null(),
])

const FilterSchema = z
  .record(
    z.string(),
    z.array(
      z.object({
        comparisonId: z.nativeEnum(FilterOperator),
        value: z.array(z.string()),
        maxValue: z.string().optional(),
      }),
    ),
  )
  .optional()

const BaseWidgetSchema = z.object({
  id: z.string(),
  gridSpan: z.number(),
  metrics: z.array(z.string()),
  dimensions: z.array(z.string()),
  filter: FilterSchema,
  allowHourlyData: z.boolean(),
})

const ActivityWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('activity'),
  realtime: z.boolean(),
  title: z.string(),
})

const MarketsWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('market'),
  selectedMetric: z.string(),
  title: z.string(),
  metrics: z.record(
    z.string(),
    z.object({
      key: z.string(),
      predictionKey: z.string().optional(),
    }),
  ),
})

const BarWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('bar'),
  selectedMetric: z.string(),
  title: z.string(),
  metrics: z.record(
    z.string(),
    z.object({
      key: z.string(),
      predictionKey: z.string().optional(),
    }),
  ),
  sort: z
    .object({
      key: z.string(),
      order: z.nativeEnum(ChartSortOrder),
    })
    .optional(),
})

const LineWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('graph'),
  title: z.string().optional(),
  isCumulative: z.boolean().optional(),
})

const MultiMetricWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('multiMetricChart'),
  title: z.string(),
  showComparison: z.boolean(),
  selectedMetrics: z.array(z.string()),
  chartType: z.enum(Object.keys(chartTypes) as [ChartTypeId, ...ChartTypeId[]]),
  sortOrder: z.nativeEnum(ChartSortOrder).optional(),
})

const TopBottomWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('topBottom'),
  comparisonMetric: z.string(),
  isTop: z.boolean(),
  reportIdLink: z.string(),
  selectedDimensions: z.array(z.string()).optional(),
})

const MetricWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('metricGroup'),
  groupSize: z.number().optional(),
  size: z.enum(['sm', 'lg']),
})

// this is a more special widget for showing a group of widgets for a ads platform like google ads or facebook ads
const MarketingGroupSchema = z.object({
  id: z.string(),
  gridSpan: z.number(),
  type: z.literal('marketingGroup'),
  title: z.string(),
  subtitle: z.string(),
  logo: z.string(),
  widgets: z.array(
    z.union([
      ActivityWidgetSchema,
      LineWidgetSchema,
      MultiMetricWidgetSchema,
      TopBottomWidgetSchema,
      MarketsWidgetSchema,
      MetricWidgetSchema,
    ]),
  ),
})

const DonutWidgetSchema = BaseWidgetSchema.extend({
  type: z.literal('donut'),
  title: z.string(),
  filter: FilterSchema,
  hasAttribution: z.boolean().optional(),
})

export type MarketsWidgetType = z.infer<typeof MarketsWidgetSchema>
export type BarWidgetType = z.infer<typeof BarWidgetSchema>
export type TopBottomWidgetType = z.infer<typeof TopBottomWidgetSchema>
export type ActivityWidgetType = z.infer<typeof ActivityWidgetSchema>
export type LineWidgetType = z.infer<typeof LineWidgetSchema>
export type MultiMetricWidgetType = z.infer<typeof MultiMetricWidgetSchema>
export type MetricWidgetType = z.infer<typeof MetricWidgetSchema>
export type MarketingGroupType = z.infer<typeof MarketingGroupSchema>
export type DonutWidgetType = z.infer<typeof DonutWidgetSchema>

// Update this when you want to force push out a new version
// the dashboard state is stored in local storage
const DASHBOARD_VERSION = '1.15'

const dashboardStateSchema = z.object({
  id: z.string(),
  dateState: z.object({
    dateRange: z.tuple([DateValueRange, DateValueRange]),
    dynamicDate: z.nativeEnum(DYNAMIC_DATE_ID),
    compareDateRange: z.tuple([DateValueRange, DateValueRange]),
    compareDynamicDate: z.nativeEnum(COMPARE_DYNAMIC_DATE_ID),
  }),
  version: z.literal(DASHBOARD_VERSION),
  globalFilters: z.record(z.nativeEnum(GLOBAL_FILTER), GlobalFilterSchema),
  selectedDashboardId: z.string(),
  dashboards: z.array(
    z.object({
      id: z.string(),
      label: z.string(),
      globalFilterIds: z.array(z.nativeEnum(GLOBAL_FILTER)),
      dynamicDateRanges: z.array(z.nativeEnum(DYNAMIC_DATE_ID)),
      compareDynamicDateRanges: z.array(z.nativeEnum(COMPARE_DYNAMIC_DATE_ID)),
      defaultDateRange: z.nativeEnum(DYNAMIC_DATE_ID),
      widgets: z.array(
        z.union([
          ActivityWidgetSchema,
          LineWidgetSchema,
          MultiMetricWidgetSchema,
          TopBottomWidgetSchema,
          MarketsWidgetSchema,
          BarWidgetSchema,
          MetricWidgetSchema,
          MarketingGroupSchema,
          DonutWidgetSchema,
        ]),
      ),
    }),
  ),
})

export const removeDashboardLocalStorageKeys = () => {
  localStorage.removeItem(DASHBOARD_STATE_KEY)
}

export type DashboardState = z.infer<typeof dashboardStateSchema>

export const dashboardStateAtom = atomWithStorage<DashboardState>(
  DASHBOARD_STATE_KEY,
  {
    id: 'dashboard-state-id',
    dateState: {
      dateRange: defaultDate.value,
      dynamicDate: defaultDate.id as DYNAMIC_DATE_ID,
      compareDateRange: defaultCompare?.value as DateRange,
      compareDynamicDate: defaultCompare?.id as COMPARE_DYNAMIC_DATE_ID,
    },
    version: DASHBOARD_VERSION,
    globalFilters,
    selectedDashboardId: overviewDashboard.id,
    dashboards: [overviewDashboard, marketingDashboard, inventoryDashboard],
  },
  {
    getItem(key, initialValue) {
      try {
        const storedValue = localStorage.getItem(key)

        return dashboardStateSchema.parse(JSON.parse(storedValue ?? ''))
      } catch {
        // The validation failed, so we remove the invalid state
        removeDashboardLocalStorageKeys()

        return initialValue
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    removeItem() {
      removeDashboardLocalStorageKeys()
    },
  },
)

export const dashboardsAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('dashboards'),
)

export const selectedDashboardIdAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('selectedDashboardId'),
)

export const dashboardVersionAtom = focusAtom(dashboardStateAtom, (optic) =>
  optic.prop('version'),
)
// Add a selector to get the selected dashboard based on selectedDashboardId
const selectedDashboardSelector = atom(
  (get) => {
    const dashboards = get(dashboardsAtom)
    const selectedDashboardId = get(selectedDashboardIdAtom)

    const selectedDashboard = dashboards.find(
      (dashboard) => dashboard.id === selectedDashboardId,
    )

    const { merchantId } = get(merchantInfoAtom)

    const overwriteDashboard = overwriteDashboards[merchantId]?.find(
      (dashboard) => dashboard.id === selectedDashboardId,
    )

    if (!overwriteDashboard) {
      return selectedDashboard || dashboards[0]
    }

    // If the selected dashboard is not found, default to the first dashboard
    return selectedDashboard
      ? ({
          ...selectedDashboard,
          ...overwriteDashboard,
          widgets: selectedDashboard.widgets.map((widget) => ({
            ...widget,
            ...overwriteDashboard?.widgets?.find((w) => w.id === widget.id),
          })),
        } as DashboardState['dashboards'][number])
      : dashboards[0]
  },
  (get, set, updatedDashboard: DashboardState['dashboards'][number]) => {
    const dashboards = get(dashboardsAtom)
    const selectedDashboardId = updatedDashboard.id

    // Update selectedDashboardIdAtom
    set(selectedDashboardIdAtom, selectedDashboardId)

    // Update dashboardsAtom
    const updatedDashboards = dashboards.map((dashboard) =>
      dashboard.id === selectedDashboardId ? updatedDashboard : dashboard,
    )

    set(dashboardsAtom, updatedDashboards)
  },
)

// Modify the selectedDashboardAtom to use the selector
export const selectedDashboardAtom = focusAtom(
  selectedDashboardSelector,
  (optic) => optic,
)
