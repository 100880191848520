import { Box, Divider, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { RadioCard } from 'components/RadioCard/RadioCard'
import { Typography } from 'components/Typography'
import { addDays } from 'date-fns'
import { useExperiment } from 'features/geoLift/graphql/useExperiment'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import { StatusTag } from 'features/geoLift/shared/StatusTag'
import { type ExperimentSetupFieldsFragment } from 'generated/graphql/graphql'
import { useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { CausalEffectChart } from './components/CausalEffectChart'
import { ExperimentConfigPanel } from './components/ExperimentConfigPanel'
import { ExperimentStatistics } from './components/ExperimentStatistics'
import { FinalizeExperimentButton } from './components/FinalizeExperimentButton'
import { GeographicalTargeting } from './components/GeographicalTargeting'
import { KPIChart } from './components/KPIChart'
import { UpdateResultsButton } from './components/UpdateResultsButton'

export const ExperimentView: React.FC = () => {
  const { id } = useParams()
  const { query, experiment } = useExperiment(String(id))
  const [setupVariant, setSetupVariant] = useState<
    'cost' | 'statisticalSignificance'
  >('cost')
  const hasSetup = !!experiment?.setup

  const selectedSetup =
    experiment?.setup ||
    experiment?.suggestions?.reduce<ExperimentSetupFieldsFragment | undefined>(
      (min, suggestion) => {
        const suggestionValue =
          setupVariant === 'cost'
            ? Math.abs(suggestion.channelMetrics.estimatedTotalSpend)
            : suggestion.absLiftInZero

        const minValue =
          setupVariant === 'cost'
            ? Math.abs(min?.channelMetrics?.estimatedTotalSpend ?? Infinity)
            : min?.absLiftInZero

        return suggestionValue < (minValue ?? Infinity) ? suggestion : min
      },
      undefined,
    )

  return (
    <Flex direction="column" h="full" overflowY="auto">
      <Flex
        py={2}
        px={4}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Flex alignItems="center">
          <Link to={GEO_LIFT_PAGES.EXPERIMENTS}>
            <Typography
              color="gray.900"
              fontSize="medium"
              fontWeight={500}
              lineHeight={6}
            >
              Experiments
            </Typography>
          </Link>

          <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
            /
          </Typography>

          {query.loading ? (
            <Skeleton h={5} w={20} />
          ) : experiment?.status ? (
            <StatusTag size="large" status={experiment?.status} />
          ) : null}
        </Flex>

        {id && selectedSetup && experiment ? (
          !hasSetup && experiment.setup?.isValidExperiment === true ? (
            <FinalizeExperimentButton
              experimentId={id}
              selectedSetupId={selectedSetup.id}
            />
          ) : (
            (experiment.status === 'completed' ||
              experiment.status === 'finalized') && (
              <UpdateResultsButton
                experimentId={id}
                selectedSetupId={selectedSetup.id}
              />
            )
          )
        ) : null}
      </Flex>

      <Flex flex={1}>
        <Box flex={1}>
          {query.loading ? (
            <Box py={4} px={6}>
              <Skeleton h={52} w="full" mb={3} />
              <Skeleton h={52} w="full" mb={3} />
              <Skeleton h={52} w="full" />
            </Box>
          ) : query.error || !experiment?.suggestions ? (
            <div>no data</div>
          ) : (
            <>
              {experiment?.status === 'configuring' ? (
                <Box px={6} py={4}>
                  <Box maxW={400}>
                    <Typography
                      fontSize="sm"
                      fontWeight={500}
                      lineHeight={5}
                      color="gray.800"
                      mb={8}
                    >
                      Experiment design
                    </Typography>

                    <ProgressBar
                      title="Configuring experiment"
                      description="You can continue using the platform as normal during the configuration."
                      progress={null}
                    />
                  </Box>
                </Box>
              ) : experiment?.status === 'failed' ? (
                <Box px={6} py={4}>
                  <Box maxW={400}>
                    <Typography
                      fontSize="sm"
                      fontWeight={500}
                      lineHeight={5}
                      color="gray.800"
                      mb={8}
                    >
                      Experiment design
                    </Typography>

                    <ProgressBar
                      title="Configuring experiment"
                      description="Unable to create experiment setups. Try changing some experiment parameters."
                      progress={100}
                      status="error"
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  {experiment.setup?.isValidExperiment === false && (
                    <Box px={6} py={4}>
                      <Alert
                        status="error"
                        content="We were unable to create a valid experiment setup. The estimated spend for the selected channel and selected test period is not high enough. Please select another channel and/or a longer test period."
                      />
                    </Box>
                  )}

                  {addDays(
                    new Date(experiment.config.endDate),
                    experiment.config.treatmentPeriod,
                  ) > new Date() && (
                    <Box px={6} py={4}>
                      <Alert
                        status="info"
                        content="The test period has ended and you can find the initial results from the experiment below. When the post-treatment period ends the results will automatically be updated. You can also update the results at any time."
                      />
                    </Box>
                  )}

                  {!hasSetup && (
                    <>
                      <Box px={6} py={4}>
                        <Typography
                          fontSize="sm"
                          fontWeight={500}
                          lineHeight={5}
                          color="gray.800"
                          mb={4}
                        >
                          Suggested experiment setup
                        </Typography>

                        <SimpleGrid columns={2} gap={4}>
                          <RadioCard
                            label="Cost"
                            description="Experiment setup optimized to minimize test cost."
                            selected={setupVariant === 'cost'}
                            onChange={() => setSetupVariant('cost')}
                          />

                          <RadioCard
                            label="Statistical significance"
                            description="Experiment setup optimized for statistical significance."
                            selected={
                              setupVariant === 'statisticalSignificance'
                            }
                            onChange={() =>
                              setSetupVariant('statisticalSignificance')
                            }
                          />
                        </SimpleGrid>
                      </Box>
                      <Divider />
                    </>
                  )}

                  {selectedSetup && (
                    <>
                      <ExperimentStatistics
                        setup={selectedSetup}
                        targetVariable={experiment.config.targetVariable}
                      />
                      <Divider />
                      <GeographicalTargeting
                        setup={selectedSetup}
                        experimentName={experiment?.config.name}
                      />
                    </>
                  )}

                  {experiment?.inference && (
                    <>
                      <Divider />
                      <KPIChart
                        inference={experiment.inference}
                        targetVariable={experiment.config.targetVariable}
                        startDate={experiment.config.startDate}
                        endDate={experiment.config.endDate}
                        treatmentPeriod={experiment.config.treatmentPeriod}
                      />

                      <Divider />
                      <CausalEffectChart
                        cumulativeLift={experiment.inference.cumulativeLift}
                        targetVariable={experiment.config.targetVariable}
                        startDate={experiment.config.startDate}
                        endDate={experiment.config.endDate}
                        treatmentPeriod={experiment.config.treatmentPeriod}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        <ExperimentConfigPanel
          config={experiment?.config}
          experimentStatus={experiment?.status}
          loading={query.loading}
        />
      </Flex>
    </Flex>
  )
}
