import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { TableBodyRow } from './TableBodyRow'
import { TableHeaderCell } from './TableHeaderCell'
import type { ColumnAllSelected, MultiCheckboxSelectProps } from './types'

const MultiCheckboxSelect = ({
  columns,
  rows,
  selectedItems,
  setSelectedItems,
}: MultiCheckboxSelectProps) => {
  const columnAllSelected = useMemo(
    () =>
      columns.reduce<ColumnAllSelected>((acc, column) => {
        acc[column.id] =
          selectedItems.filter((draftItem) => draftItem.columnId === column.id)
            .length ===
          rows.filter((row) => !row.disabledColumns.includes(column.id)).length

        return acc
      }, {}),
    [columns, rows, selectedItems],
  )

  const toggleSelectedItem = (rowId: string, columnId: string) => {
    setSelectedItems(
      selectedItems.some(
        (item) => item.rowId === rowId && item.columnId === columnId,
      )
        ? selectedItems.filter(
            (item) => !(item.rowId === rowId && item.columnId === columnId),
          )
        : [...selectedItems, { rowId, columnId }],
    )
  }

  const onSelectAll = (columnId: string) => {
    const newSelectedItems = rows
      .filter(
        (row) =>
          !selectedItems.some(
            (item) => item.rowId === row.id && item.columnId === columnId,
          ) && !row.disabledColumns.includes(columnId),
      )
      .map((row) => ({ rowId: row.id, columnId }))

    setSelectedItems([...newSelectedItems, ...selectedItems])
  }

  const onDeselectAll = (columnId: string) => {
    setSelectedItems(
      selectedItems.filter((item) => !(item.columnId === columnId)),
    )
  }

  return (
    <Box overflowY="auto">
      <table>
        <thead>
          <Box as="tr" h="58px">
            <Box as="th" />
            {columns.map((column) => {
              const isAllSelected = columnAllSelected[column.id]

              return (
                <TableHeaderCell
                  key={column.id}
                  label={column.label}
                  description={column.description}
                  color={column.color}
                  iconName={column.iconName}
                  iconColor={column.iconColor}
                  isAllSelected={isAllSelected}
                  toggleAllSelected={() =>
                    isAllSelected
                      ? onDeselectAll(column.id)
                      : onSelectAll(column.id)
                  }
                />
              )
            })}
          </Box>
        </thead>
        {rows.length > 0 && (
          <tbody>
            {rows.map((row) => (
              <TableBodyRow
                key={row.id}
                columns={columns}
                row={row}
                selectedItems={selectedItems}
                toggleSelectedItem={toggleSelectedItem}
              />
            ))}
          </tbody>
        )}
      </table>
    </Box>
  )
}

export default MultiCheckboxSelect
