import { DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import { Box, Skeleton, Stack } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { type ExperimentStatus } from 'features/geoLift/graphql/fragments'
import { StatusTag } from 'features/geoLift/shared/StatusTag'
import { formatTestPeriodFromDays } from 'features/geoLift/utils'
import { type ExperimentConfigFieldsFragment } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useState } from 'react'
import { formatMetric } from 'utils/numberFormats'
import { EXPERIMENT_METRIC_IDS } from '../../ExperimentsView/components/CreateExperimentDrawer/CreateExperimentDrawer'
import { DetailRow } from './DetailRow'

interface ExperimentConfigPanelProps {
  config: ExperimentConfigFieldsFragment | undefined
  experimentStatus: ExperimentStatus | undefined
  loading: boolean
}

export const ExperimentConfigPanel: React.FC<ExperimentConfigPanelProps> = ({
  config,
  experimentStatus,
  loading,
}) => {
  const normalizedMetrics = useNormalizedMetrics()

  if (loading)
    return (
      <Box
        flexShrink={0}
        py={4}
        px={6}
        w="300px"
        h="full"
        borderLeft="1px solid"
        borderColor="gray.200"
      >
        <Typography
          fontSize="sm"
          fontWeight={500}
          lineHeight={5}
          color="gray.800"
        >
          Experiment details
        </Typography>

        <Stack spacing={2} mt={2}>
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
          <Skeleton w="full" height={8} />
        </Stack>
      </Box>
    )

  if (!config || !experimentStatus) return null

  return (
    <Box
      flexShrink={0}
      py={4}
      px={6}
      w="300px"
      h="full"
      borderLeft="1px solid"
      borderColor="gray.200"
    >
      <Typography
        fontSize="sm"
        fontWeight={500}
        lineHeight={5}
        color="gray.800"
      >
        Experiment details
      </Typography>

      <Stack>
        <DetailRow
          label="Status"
          value={<StatusTag status={experimentStatus} size="medium" />}
        />
        <DetailRow label="Name" value={config.name} />
        <DetailRow label="Country" value={config.country} />
        <DetailRow label="Channel" value={config.channel} />
        <DetailRow label="Funnel" value={config.funnelCampaign} />
        <DetailRow
          label="Campaigns"
          value={<CampaignDetails config={config} />}
        />
        <DetailRow
          label="Target KPI"
          value={
            normalizedMetrics[config.targetVariable].label ||
            config.targetVariable
          }
        />
        <DetailRow
          label={
            config.targetVariable === EXPERIMENT_METRIC_IDS.ORDER_TOTAL
              ? 'Average ROAS'
              : 'Average epROAS'
          }
          value={formatMetric(
            METRIC_FORMAT.PERCENT_INT,
            config.targetReturnOnSpend,
          )}
        />
        <DetailRow label="Start date" value={config.startDate} />
        <DetailRow
          label="Treatment period"
          value={formatTestPeriodFromDays(config.treatmentPeriod)}
        />
        {config.endDate ? (
          <DetailRow label="End date" value={config.endDate} />
        ) : null}
        <DetailRow
          label="Post treatment period"
          value={formatTestPeriodFromDays(config.treatmentPeriod / 2)}
        />
      </Stack>
    </Box>
  )
}

const CampaignDetails: React.FC<{ config: ExperimentConfigFieldsFragment }> = ({
  config,
}) => {
  const [showMore, setShowMore] = useState(false)
  const lastMonth = getDatePreset(DYNAMIC_DATE_ID.LAST_MONTH)
  const { statistics, query } = useStatistics({
    variables: {
      statisticsParams: {
        frontendIds: [config.frontendId],
        dimensions: ['campaign'],
        metrics: ['cost:total'],
        startDate: String(lastMonth.value[0]?.toISOString()),
        endDate: String(lastMonth.value[1]?.toISOString()),
        filter: {
          channel: [
            {
              value: [config.channel],
              comparisonId: 'isAnyOf',
            },
          ],
          country: [
            {
              value: [config.country],
              comparisonId: 'isAnyOf',
            },
          ],
          funnelCampaign: [
            {
              value: [config.funnelCampaign],
              comparisonId: 'isAnyOf',
            },
          ],
        },
      },
    },
  })

  if (query.loading) return <Skeleton h={4} w={24} />

  if (query.error || !statistics.length)
    return (
      <Typography
        fontSize="sm"
        fontWeight={400}
        lineHeight={5}
        color="gray.800"
        textAlign="right"
      >
        -
      </Typography>
    )

  const campaigns = statistics?.map((item) => item.campaign.value)

  return (
    <Box>
      <Typography
        fontSize="sm"
        fontWeight={400}
        lineHeight={5}
        color="gray.800"
        textAlign="right"
        noOfLines={showMore ? undefined : 2}
      >
        {campaigns.join(' | ')}
      </Typography>

      <Button
        float="right"
        size="sm"
        variant="link"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? 'View less' : 'View more'}
      </Button>
    </Box>
  )
}
