import { Box, Flex } from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { SelectDeselectButton } from 'components/Filters/SelectDeselectButton'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'

interface Props {
  label: string
  description: string
  color: string
  iconName: IconName
  iconColor: string
  isAllSelected: boolean
  toggleAllSelected: () => void
}

export const TableHeaderCell = ({
  label,
  description,
  color,
  iconName,
  iconColor,
  isAllSelected,
  toggleAllSelected,
}: Props) => {
  return (
    <Box
      as="th"
      zIndex={1}
      p={3}
      borderX="none"
      w="148px"
      borderColor="grey.200"
      verticalAlign="top"
    >
      <Flex justifyContent="center" width="100%">
        <Badge
          display="flex"
          bg={color}
          gap={2}
          px={1.5}
          py={1}
          alignItems="center"
          justifyContent="center"
          width="max-content"
        >
          <Icon name={iconName} color={iconColor} size="small" />
          <Typography fontSize="xs" fontWeight="400" color={iconColor}>
            {label}
          </Typography>
        </Badge>
      </Flex>
      <Flex justifyContent="center" height="48px" mt={2}>
        <Typography fontSize="12px" lineHeight="16px" color="grey.500">
          {description}
        </Typography>
      </Flex>
      <Flex justifyContent="center" mt={4}>
        <SelectDeselectButton
          isAllSelected={isAllSelected}
          toggleAllSelected={toggleAllSelected}
          size="sm"
        />
      </Flex>
    </Box>
  )
}
