import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type Experiment } from './fragments'

export const EXPERIMENT_QUERY = graphql(/* GraphQL */ `
  query Experiment($id: ID!) {
    viewer {
      id
      merchant {
        id
        experiment(id: $id) {
          ...ExperimentFields
        }
      }
    }
  }
`)

export const useExperiment = (id: string) => {
  const { data, ...query } = useQuery(EXPERIMENT_QUERY, {
    variables: { id },
  })

  return {
    query,
    experiment: data?.viewer?.merchant.experiment as Experiment | null,
  }
}
