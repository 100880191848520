import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import {
  type SegmentationSetByIdQueryResult,
  useSegmentationSetById,
} from 'features/productFeed/graphql/useSegmentationSetById'
import { PRODUCT_FEED_PAGES } from 'features/productFeed/routePages'
import { EmptyState } from 'features/productFeed/shared/EmptyState'
import { ProductFeedHeaderWrapper } from 'features/productFeed/shared/ProductFeedHeaderWrapper'
import { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { RulesContainer } from './components/RulesContainer/RulesContainer'
import { SegmentationPieCharts } from './components/RulesContainer/SegmentationPieCharts'
import { type RuleForm } from './components/RulesContainer/types'
import { getEmptyRule } from './components/RulesContainer/utils'
import { SegmentRuleHeader } from './components/SegmentRuleHeader'

export const SegmentationRulesView: React.FC = () => {
  const { id } = useParams()
  const { segmentationSet, query } = useSegmentationSetById(id ?? '')

  if (query.loading)
    return (
      <Box>
        <ProductFeedHeaderWrapper>
          {/* minH needed for the dropdown */}
          <Flex alignItems="center" minH={8}>
            <ProductFeedHeaderWrapper.Title>
              Product set
            </ProductFeedHeaderWrapper.Title>

            <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
              /
            </Typography>

            <Skeleton h={5} w={32} />
          </Flex>
        </ProductFeedHeaderWrapper>

        <Box p={4}>
          <Skeleton h={52} w="full" mb={4} />
          <Skeleton h={52} w="full" />
        </Box>
      </Box>
    )

  if (query.error || !segmentationSet)
    return (
      <EmptyState
        imageSrc="/images/Layers.svg"
        title="Product set not found"
        description="The product set you are looking for does not exist."
        modalButton={
          <Link to={PRODUCT_FEED_PAGES.SEGMENTATION_SETS}>
            <Button
              mt={4}
              variant="solid"
              size="sm"
              leadingIcon={{ name: 'ArrowLeftIcon' }}
            >
              Go back to product sets
            </Button>
          </Link>
        }
      />
    )

  return <SegmentationContent segmentationSet={segmentationSet} />
}

const SegmentationContent: React.FC<{
  segmentationSet: SegmentationSetByIdQueryResult
}> = ({ segmentationSet }) => {
  const [selectedDatePresetId, setSelectedDatePresetId] = useState(
    segmentationSet.dynamicDate,
  )

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ rules: RuleForm[] }>({
    defaultValues: {
      rules:
        segmentationSet.rules?.length > 0
          ? segmentationSet.rules
          : [getEmptyRule()],
    },
  })

  const methods = useFieldArray({
    control,
    name: 'rules',
    // the id generated here changes on every change. Which makes the state inside of the cards unstable
    // so we use our own generated id
    keyName: 'internal_id',
  })

  return (
    <Box>
      <SegmentRuleHeader
        segmentationSet={segmentationSet}
        selectedDatePresetId={selectedDatePresetId}
        setSelectedDatePresetId={setSelectedDatePresetId}
      />

      <Flex p={4} gap={4}>
        <Box w="full">
          <RulesContainer
            segmentationSet={segmentationSet}
            selectedDatePresetId={selectedDatePresetId}
            methods={methods}
            handleSubmit={handleSubmit}
            isDirty={isDirty}
          />
        </Box>

        <SegmentationPieCharts
          selectedDatePresetId={selectedDatePresetId}
          rules={methods.fields}
        />
      </Flex>
    </Box>
  )
}
