import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { CopyCSVLinkModal } from 'features/productFeed/shared/CopyCSVLinkModal'
import { ProductFeedHeaderWrapper } from 'features/productFeed/shared/ProductFeedHeaderWrapper'
import { useState } from 'react'
import { AddSegmentationSetModal } from './AddSegmentationSetModal'

export const SegmentationHeader: React.FC = () => {
  const [createdSegmentationSet, setCreatedSegmentationSet] = useState<
    { name: string; id: string } | undefined
  >(undefined)
  const csvLinkModalDisclosure = useDisclosure()

  return (
    <ProductFeedHeaderWrapper>
      <ProductFeedHeaderWrapper.Title>
        Product sets
      </ProductFeedHeaderWrapper.Title>

      <AddSegmentationSetModal
        modalButton={
          <Button
            size="sm"
            leadingIcon={{ name: 'PlusSmallIcon' }}
            colorScheme="gray"
          >
            New product set
          </Button>
        }
        onSegmentationSetCreated={(segmentation) => {
          setCreatedSegmentationSet(segmentation)
          csvLinkModalDisclosure.onOpen()
        }}
      />

      <CopyCSVLinkModal
        isOpen={csvLinkModalDisclosure.isOpen}
        onClose={csvLinkModalDisclosure.onClose}
        segmentationSetName={createdSegmentationSet?.name ?? ''}
        segmentationSetId={createdSegmentationSet?.id ?? ''}
      />
    </ProductFeedHeaderWrapper>
  )
}
