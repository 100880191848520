import { Flex } from '@chakra-ui/react'
import { Typography } from 'components/Typography'

export const DetailRow: React.FC<{
  label: string
  value: string | React.ReactElement
}> = ({ label, value }) => {
  return (
    <Flex
      py={2.5}
      justifyContent="space-between"
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      _last={{
        borderBottom: 'none',
      }}
    >
      <Typography
        fontSize="xs"
        fontWeight={500}
        lineHeight={5}
        color="gray.600"
        mr={4}
      >
        {label}
      </Typography>
      {typeof value === 'string' ? (
        <Typography
          fontSize="sm"
          fontWeight={400}
          lineHeight={5}
          color="gray.800"
          textAlign="right"
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </Flex>
  )
}
