import { Flex } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import {
  reportCreatedAtAtom,
  reportUpdatedAtAtom,
} from 'features/reports/hooks/useReportState'
import { useAtomValue } from 'jotai'
import { formatDate } from 'utils/formatDate'

export const AdditionalInfoSection: React.FC = () => {
  const createdAt = useAtomValue(reportCreatedAtAtom)
  const updatedAt = useAtomValue(reportUpdatedAtAtom)
  const formattedCreatedAt = createdAt ? formatDate(new Date(createdAt)) : null
  const formattedUpdatedAt = updatedAt ? formatDate(new Date(updatedAt)) : null

  return (
    <Flex direction="column" gap={1} p={4}>
      {formattedCreatedAt && (
        <Typography fontSize="xs" color="grey.600" lineHeight={4}>
          Created{' '}
          <Typography
            as="time"
            lineHeight={4}
            fontSize="xs"
            color="gray.800"
            dateTime={formattedCreatedAt}
          >
            {formattedCreatedAt}
          </Typography>
        </Typography>
      )}
      {formattedUpdatedAt && (
        <Typography fontSize="xs" color="grey.600" lineHeight={4}>
          Last updated{' '}
          <Typography
            as="time"
            lineHeight={4}
            fontSize="xs"
            color="gray.800"
            dateTime={formattedUpdatedAt}
          >
            {formattedUpdatedAt}
          </Typography>
        </Typography>
      )}
    </Flex>
  )
}
