import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  GridItem,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { SingleDatepicker } from 'components/Datepicker/SingleDatepicker/SingleDatepicker'
import { Dropdown } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { addDays, format } from 'date-fns'
import { useCreateExperiment } from 'features/geoLift/graphql/useCreateExpirment'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { z } from 'zod'

import { FunnelDropdown } from './FunnelDropdown'
import { TargetReturnOnSpendInput } from './TargetReturnOnSpendInput'

interface CreateExperimentDrawerProps {
  isOpen: boolean
  onClose: () => void
}

const validationSchema = z.object({
  name: z.string().min(1),
  frontendId: z.string().min(1, { message: 'Please select a storefront' }),
  country: z.string().min(1, { message: 'Please select a country' }),
  channel: z.string().min(1, { message: 'Please select a channel' }),
  funnelCampaign: z.string().min(1, { message: 'Please select a funnel' }),
  targetVariable: z.string().min(1, { message: 'Please select a target KPI' }),
  targetReturnOnSpend: z.coerce
    .number()
    .min(100, { message: 'Must be greater or equal to 100%' })
    .max(1000, { message: 'Must be less or equal to 1000%' }),
  startDate: z.date({ message: 'Please select a start date' }),
  treatmentPeriod: z
    .number({
      message: 'Please select a test period',
    })
    .min(1, { message: 'Please select a test period' }),
})

export type ValidationSchema = z.infer<typeof validationSchema>

export const EXPERIMENT_METRIC_IDS = {
  ORDER_TOTAL: 'order:total:dema',
  ORDER_NET_GROSS_PROFIT: 'order:netGrossProfit2:dema',
} as const

export const CreateExperimentDrawer: React.FC<CreateExperimentDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { sites = [] } = useMerchantInfo()
  const siteOptions = useMemo(
    () =>
      (sites ?? []).map((site) => ({ id: site.frontendId, name: site.domain })),
    [sites],
  )
  const { countries, query: countryQuery } = useCountries()
  const { channels, query: channelsQuery } = useChannels()

  const [createExperimentMutation, createExperimentStatus] =
    useCreateExperiment()

  const navigate = useNavigate()
  const toast = useToast()

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: '',
      frontendId: sites[0].frontendId,
      country: '',
      channel: '',
      funnelCampaign: '',
      targetVariable: '',
      targetReturnOnSpend: NaN,
      startDate: null as unknown as Date,
      treatmentPeriod: 0,
    },
  })

  const {
    watch,
    setValue,
    formState: { errors },
  } = methods

  const onSubmit: SubmitHandler<ValidationSchema> = (args) => {
    createExperimentMutation({
      variables: {
        params: {
          ...args,
          startDate: format(args.startDate, 'yyyy-MM-dd'),
          purpose: 'inverse',
          channelGroup: 'social_ppc',
        },
      },
      onCompleted: (data) => {
        navigate(
          GEO_LIFT_PAGES.EXPERIMENT_BY_ID(data.createExperiment.config.id),
        )

        toast({ description: 'Successfully created experiment' })
      },
      onError: (error) => {
        console.error(error)
        toast({ description: 'Failed to create experiment', status: 'error' })
      },
    })
  }

  const metrics = useNormalizedMetrics()

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <Form<ValidationSchema> methods={methods} onSubmit={onSubmit}>
        <DrawerContent position="relative" overflowY="auto">
          <DrawerHeader>
            Add new experiment
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody>
            <Typography
              fontSize="medium"
              color="color.900"
              lineHeight={6}
              mb={2}
            >
              Experiment details
            </Typography>

            <Typography fontSize="sm" color="color.800" lineHeight={5}>
              Specify the details of the experiment. Based on what you select we
              will suggest statistically optimised experiment designs.
            </Typography>

            <SimpleGrid columns={2} spacingY={6} spacingX={4} mt={6}>
              <Input
                name="name"
                label="Experiment name"
                placeholder="E.g Facebook lower US"
                type="text"
                error={errors.name}
              />

              <Dropdown
                options={siteOptions}
                value={watch('frontendId')}
                label="Storefront"
                error={errors.frontendId}
                placeholder="Select storefront"
                callback={(option) =>
                  setValue('frontendId', option.id.toString(), {
                    shouldValidate: true,
                  })
                }
              />

              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                options={countries}
                selected={watch('country')}
                label="Country"
                isLoading={countryQuery.loading}
                error={errors.country}
                placeholder="Select Country"
                setSelected={(option) =>
                  setValue('country', option, { shouldValidate: true })
                }
              />

              <GridItem colSpan={2}>
                <Divider />
              </GridItem>

              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                options={channels}
                selected={watch('channel')}
                label="Channel"
                isLoading={channelsQuery.loading}
                error={errors.channel}
                placeholder="Select Channel"
                setSelected={(option) =>
                  setValue('channel', option, { shouldValidate: true })
                }
              />

              <FunnelDropdown
                funnelCampaign={watch('funnelCampaign')}
                error={errors.funnelCampaign}
                onChange={(option) =>
                  setValue('funnelCampaign', option, { shouldValidate: true })
                }
              />

              <Dropdown
                options={[
                  {
                    id: EXPERIMENT_METRIC_IDS.ORDER_TOTAL,
                    name: metrics[EXPERIMENT_METRIC_IDS.ORDER_TOTAL]?.label,
                  },
                  {
                    id: EXPERIMENT_METRIC_IDS.ORDER_NET_GROSS_PROFIT,
                    name: metrics[EXPERIMENT_METRIC_IDS.ORDER_NET_GROSS_PROFIT]
                      ?.label,
                  },
                ]}
                value={watch('targetVariable')}
                label="Target KPIs"
                error={errors.targetVariable}
                placeholder="Select Target KPI"
                callback={(option) => {
                  setValue('targetVariable', option.id.toString(), {
                    shouldValidate: true,
                  })
                }}
              />

              <TargetReturnOnSpendInput
                targetVariable={watch('targetVariable')}
                targetReturnOnSpend={watch('targetReturnOnSpend')}
                error={errors.targetReturnOnSpend}
                onChange={(value) =>
                  setValue('targetReturnOnSpend', value as number, {
                    shouldValidate: true,
                  })
                }
              />

              <GridItem colSpan={2}>
                <Divider />
              </GridItem>

              <SingleDatepicker
                selectedDate={watch('startDate')}
                placement="bottom"
                setSelectedDate={(date) => {
                  setValue('startDate', date as Date, { shouldValidate: true })
                }}
                minDate={addDays(new Date(), 1)}
                placeholder="DD MMM, YYYY"
                label="Start date"
                error={errors.startDate}
              />

              <Dropdown
                options={[
                  { id: 14, name: '2 Weeks' },
                  { id: 28, name: '4 Weeks' },
                  { id: 42, name: '6 Weeks' },
                ]}
                value={watch('treatmentPeriod')}
                label="Test period"
                error={errors.treatmentPeriod}
                placeholder="Select test period"
                callback={(option) =>
                  setValue('treatmentPeriod', option.id, {
                    shouldValidate: true,
                  })
                }
              />
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              mode="dark"
              isLoading={createExperimentStatus.loading}
            >
              Create experiment
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Form>
    </Drawer>
  )
}
