import { Box, Divider, Grid } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type ExperimentSetupFieldsFragment } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { formatMetric } from 'utils/numberFormats'
import { DetailRow } from './DetailRow'

interface ExperimentStatisticsProps {
  setup: ExperimentSetupFieldsFragment
  targetVariable: string
}

export const ExperimentStatistics: React.FC<ExperimentStatisticsProps> = ({
  setup,
  targetVariable,
}) => {
  const { currency } = useMerchantInfo()
  const normalizedMetrics = useNormalizedMetrics()

  return (
    <Box py={4} px={6}>
      <Typography
        fontSize="sm"
        fontWeight={500}
        lineHeight={5}
        color="gray.800"
        mb={4}
      >
        Experiment statistics
      </Typography>

      <Grid templateColumns="1fr auto 1fr" gap={10}>
        <Box>
          <DetailRow
            label="Estimated total spend"
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              setup.channelMetrics.estimatedTotalSpend,
              currency,
            )}
          />
          <DetailRow
            label="Total spend previous period"
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              setup.channelMetrics.estimatedTotalSpendPastPeriod,
              currency,
            )}
          />
          <DetailRow
            label={`Estimated total ${(normalizedMetrics[targetVariable]?.label ?? targetVariable).toLowerCase()}`}
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              setup.channelMetrics.estimatedTotalTarget,
              currency,
            )}
          />
          <DetailRow
            label={`Estimated incremental ${(normalizedMetrics[targetVariable]?.label ?? targetVariable).toLowerCase()}`}
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              setup.channelMetrics.estimatedTotalTargetIncremental,
              currency,
            )}
          />
        </Box>

        <Divider orientation="vertical" />

        <Box>
          <DetailRow
            label="Treatment group proportion"
            value={formatMetric(
              METRIC_FORMAT.PERCENT_INT,
              setup.treatmentProportion,
            )}
          />
          <DetailRow
            label="Estimated lift"
            value={formatMetric(
              METRIC_FORMAT.PERCENT,
              setup.channelMetrics.estimatedLift,
            )}
          />
          <DetailRow
            label="Minimum detectable effect"
            value={formatMetric(
              METRIC_FORMAT.PERCENT,
              setup.avgMinimumDetectableEffect,
            )}
          />
          <DetailRow
            label="Treatment group correlation"
            value={formatMetric(METRIC_FORMAT.PERCENT, setup.correlation)}
          />
        </Box>
      </Grid>
    </Box>
  )
}
