import {
  Menu,
  IconButton,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  type PlacementWithLogical,
  useDisclosure,
  MenuDivider,
  useToast,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import type React from 'react'
import { DeleteExperimentModal } from './DeleteExperimentModal'

interface ExperimentContextMenuProps {
  experiment: ExperimentSummary
  placement?: PlacementWithLogical
  onDelete?: () => void
}

export const ExperimentContextMenu: React.FC<ExperimentContextMenuProps> = ({
  experiment,
  placement,
  onDelete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()

  const handleCopyLink = () => {
    const url = `${window.location.origin}${GEO_LIFT_PAGES.EXPERIMENT_BY_ID(experiment.id)}`

    navigator.clipboard.writeText(url)
  }

  const handleOpenInNewTab = () => {
    const url = GEO_LIFT_PAGES.EXPERIMENT_BY_ID(experiment.id)

    window.open(url, '_blank')
  }

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy={false}
      size="sm"
      placement={placement || 'bottom-end'}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            size="sm"
            aria-label="Options"
            border="none"
            icon={
              <Icon
                name="HorizontalMenuIcon"
                tooltipLabel="Actions"
                size="small"
              />
            }
            variant="ghost"
          />
          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleCopyLink()
                  toast({
                    description: 'Copied to clipboard',
                    status: 'success',
                  })
                }}
                icon={<Icon name="LinkIcon" size="small" />}
              >
                Copy link
              </MenuItem>
              <MenuItem
                onClick={handleOpenInNewTab}
                icon={<Icon name="LinkExternalIcon" size="small" />}
              >
                Open in new tab
              </MenuItem>

              <MenuDivider />
              <DeleteExperimentModal
                experiment={experiment}
                onDelete={onDelete}
              />
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
