import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(() => {
  return {
    overlay: {
      zIndex: 1,
    },
    header: {
      p: 4,
      fontSize: 'lg',
      fontWeight: 400,
      lineHeight: 7,
      color: 'gray.900',
      borderBottom: '1px solid',
      borderColor: 'gray.200',
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
    },
    closeButton: {
      borderRadius: 0,
      top: 4,
      right: 4,
    },
    body: {
      p: 4,
      height: '100%',
    },
    footer: {
      p: 4,
      borderTop: '1px solid',
      borderColor: 'gray.200',
      position: 'sticky',
      bottom: 0,
      backgroundColor: 'white',
      gap: 2,
    },
    dialog: {
      height: '100%',
    },
    dialogContainer: {
      zIndex: 2,
    },
  }
})

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
})
