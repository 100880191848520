import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useUpdateActiveSetup } from 'features/geoLift/graphql/useUpdateActiveSetup'
import type React from 'react'

interface UpdateResultsButtonProps {
  experimentId: string
  selectedSetupId: string
}

export const UpdateResultsButton: React.FC<UpdateResultsButtonProps> = ({
  experimentId,
  selectedSetupId,
}) => {
  const [updateActiveSetup, updateActiveSetupState] = useUpdateActiveSetup()

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="sm" onClick={() => onOpen()}>
        Update results
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update results</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Get the latest results from the experiment.</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              isLoading={updateActiveSetupState.loading}
              onClick={() => {
                updateActiveSetup({
                  variables: {
                    params: {
                      id: experimentId,
                      activeExperimentSetupId: selectedSetupId,
                    },
                  },
                  onCompleted: () => {
                    onClose()
                  },
                })
              }}
            >
              Update results
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
