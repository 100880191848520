import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import {
  topCampaignsReportId,
  topCategoriesReportId,
  topProductsReportId,
  topVouchersReportId,
} from 'features/reports/demaReports'
import { type DashboardState } from '../hooks/useDashboardState'
import { DASHBOARD_ID } from './consts'
import { GLOBAL_FILTER } from './globalFilters'

export const overviewDashboard = {
  id: DASHBOARD_ID.OVERVIEW,
  label: 'Overview',
  globalFilterIds: [
    GLOBAL_FILTER.STOREFRONT,
    GLOBAL_FILTER.CHANNEL,
    GLOBAL_FILTER.MARKET,
  ],
  dynamicDateRanges: [
    DYNAMIC_DATE_ID.TODAY,
    DYNAMIC_DATE_ID.THIS_WEEK,
    DYNAMIC_DATE_ID.THIS_MONTH,
    DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE,
    DYNAMIC_DATE_ID.YESTERDAY,
    DYNAMIC_DATE_ID.LAST_7_DAYS,
    DYNAMIC_DATE_ID.LAST_14_DAYS,
    DYNAMIC_DATE_ID.LAST_28_DAYS,
    DYNAMIC_DATE_ID.LAST_WEEK,
    DYNAMIC_DATE_ID.LAST_MONTH,
  ],
  compareDynamicDateRanges: [
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
    COMPARE_DYNAMIC_DATE_ID.LAST_BLACK_FRIDAY,
  ],
  defaultDateRange: DYNAMIC_DATE_ID.TODAY,
  widgets: [
    {
      id: 'overview-activity-bars',
      type: 'activity',
      gridSpan: 5,
      metrics: [
        'session:count',
        'order:count:dema',
        'product:views',
        'session:conversionRate:dema',
        'order:unprofitableOrders:dema',
      ],
      realtime: true,
      title: 'Activity',
      dimensions: [],
      allowHourlyData: true,
    },
    {
      id: 'overview-markets-column-chart',
      type: 'market',
      title: 'Markets overview',
      gridSpan: 7,
      metrics: {
        'order:netGrossProfit2:dema': {
          key: 'order:netGrossProfit2:dema',
          predictionKey: 'order:netGrossProfit2Forecast',
        },
        'order:total:dema': {
          key: 'order:total:dema',
          predictionKey: 'order:totalForecast',
        },
        'order:netSales:dema': {
          key: 'order:netSales:dema',
        },
        'session:count': {
          key: 'session:count',
          predictionKey: 'session:countForecast',
        },
        // We remove these metrics since market widget aggregates on hour and we can't aggregate aggregated metrics
        // 'order:avgOrderValue': {
        //   key: 'order:avgOrderValue',
        //   predictionKey: 'order:avgOrderValueForecast',
        // },
        // 'session:conversionRate': {
        //   key: 'session:conversionRate',
        // },
      },
      dimensions: ['market'],
      selectedMetric: 'order:netGrossProfit2:dema',
      allowHourlyData: true,
    },
    {
      id: 'overview-gross-sales-line-chart',
      type: 'graph',
      gridSpan: 4,
      dimensions: [],
      metrics: ['order:total:dema', 'order:totalForecast'],
      isCumulative: true,
      allowHourlyData: true,
    },
    {
      id: 'overview-ngp2-line-chart',
      type: 'graph',
      gridSpan: 4,
      dimensions: [],
      metrics: ['order:netGrossProfit2:dema', 'order:netGrossProfit2Forecast'],
      isCumulative: true,
      allowHourlyData: true,
    },
    {
      id: 'overview-aov-line-chart',
      type: 'graph',
      gridSpan: 4,
      dimensions: [],
      metrics: ['order:avgOrderValue:dema', 'order:avgOrderValueForecast'],
      allowHourlyData: true,
    },
    {
      id: 'overview-top-products-table',
      type: 'topBottom',
      gridSpan: 6,
      comparisonMetric: 'order:netGrossProfit2:dema',
      metrics: ['order:netGrossProfit2:dema', 'order:netGrossMargin2:dema'],
      dimensions: ['productName'],
      isTop: true,
      reportIdLink: topProductsReportId,
      allowHourlyData: true,
    },
    {
      id: 'overview-top-categories-table',
      type: 'topBottom',
      gridSpan: 6,
      comparisonMetric: 'order:netGrossProfit2:dema',
      metrics: ['order:netGrossProfit2:dema', 'order:netGrossMargin2:dema'],
      dimensions: [
        'category',
        'subCategory',
        'customAttribute1',
        'customAttribute2',
        'customAttribute3',
        'customAttribute4',
        'customAttribute5',
        'collection',
        'color',
      ],
      selectedDimensions: ['category'],
      isTop: true,
      reportIdLink: topCategoriesReportId,
      allowHourlyData: true,
    },
    {
      id: 'overview-top-campaigns-table',
      type: 'topBottom',
      gridSpan: 6,
      comparisonMetric: 'order:netGrossProfit2:dema',
      metrics: ['order:netGrossProfit2:dema', 'order:netGrossMargin2:dema'],
      dimensions: ['campaign'],
      isTop: true,
      reportIdLink: topCampaignsReportId,
      allowHourlyData: true,
    },
    {
      id: 'overview-top-vouchers-table',
      type: 'topBottom',
      gridSpan: 6,
      comparisonMetric: 'order:netGrossProfit2:dema',
      metrics: ['order:netGrossProfit2:dema', 'order:count:dema'], // 'session:conversionRate:dema', // will be added later
      isTop: true,
      dimensions: ['voucher'],
      reportIdLink: topVouchersReportId,
      allowHourlyData: true,
    },
  ],
} satisfies DashboardState['dashboards'][number]

export const MERCHANT = {
  VILLOID: '0191c266-715e-7bb9-86fa-bdf93bdaa895',
} as const

type Widget = Partial<DashboardState['dashboards'][number]['widgets'][number]>
type PartialDashboard = Partial<{
  [K in keyof DashboardState['dashboards'][number]]: K extends 'widgets'
    ? Widget[]
    : DashboardState['dashboards'][number][K]
}>
export const overwriteDashboards: Record<string, PartialDashboard[]> = {
  [MERCHANT.VILLOID]: [
    {
      id: DASHBOARD_ID.OVERVIEW,
      widgets: [
        {
          id: 'overview-markets-column-chart',
          type: 'bar',
          title: 'Storefront overview',
          dimensions: ['merchantSite'],
        },
      ],
    },
  ],
}
