import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table'
import { getCompareDateString } from 'components/Datepicker/RangeDatepicker/getCompareDateString/getCompareDateString'
import { Icon } from 'components/Icon/Icon'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { useExperiments } from 'features/geoLift/graphql/useExperiments'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import { StatusTag } from 'features/geoLift/shared/StatusTag'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { formatMetric } from 'utils/numberFormats'
import { ExperimentContextMenu } from './ExperimentContextMenu/ExperimentContextMenu'

const columnHelper = createColumnHelper<ExperimentSummary>()

const ColumnText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography fontSize="xs" color="gray.800" lineHeight={4}>
      {children}
    </Typography>
  )
}

export const ExperimentsTable: React.FC = () => {
  const { query, experiments } = useExperiments()
  const columns = useColumns()
  const table = useReactTable({
    data: experiments,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const tableRows = table.getRowModel().rows

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted()

                  return (
                    <Th
                      key={header.id}
                      bg="gray.50"
                      minW="100px"
                      px={2}
                      _first={{ pl: 4, borderLeft: 'none' }}
                      fontSize="xs"
                      color="gray.800"
                      fontWeight={500}
                      border="solid 1px"
                      borderColor="gray.200"
                      onClick={header.column.getToggleSortingHandler()}
                      cursor="pointer"
                    >
                      <Flex alignItems="center" gap={0.5}>
                        {flexRender(
                          header.column.columnDef.header as string,
                          header.getContext(),
                        )}
                        <Icon
                          visibility={isSorted ? 'visible' : 'hidden'}
                          name={
                            isSorted === 'asc' ? 'ArrowUpIcon' : 'ArrowDownIcon'
                          }
                          size="small"
                        />
                      </Flex>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {query.loading ? (
              <SkeletonTableRows columns={columns.length} rows={6} />
            ) : tableRows.length === 0 || query.error ? (
              <Tr>
                <Td colSpan={columns.length} border="unset">
                  <EmptyData label="No experiments available." />
                </Td>
              </Tr>
            ) : (
              tableRows.map((row) => (
                <Tr
                  key={row.id}
                  as={Link}
                  to={GEO_LIFT_PAGES.EXPERIMENT_BY_ID(row.original.id)}
                  display="table-row" // mimic a table row
                  verticalAlign="inherit"
                  _hover={{
                    bg: 'gray.50',
                    cursor: 'pointer',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      px={2}
                      _first={{ pl: 4, borderLeft: 'none' }}
                      border="solid 1px"
                      borderColor="gray.200"
                      h={12}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const useColumns = () => {
  const { currency } = useMerchantInfo()
  const normalizedMetrics = useNormalizedMetrics()

  return useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ({ getValue }) => {
          return <ColumnText>{getValue()}</ColumnText>
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ getValue }) => {
          return <StatusTag status={getValue()} size="small" />
        },
      }),
      columnHelper.accessor('startDate', {
        header: 'Test period',
        cell: ({ row }) => {
          return (
            <ColumnText>
              {getCompareDateString(
                new Date(row.original.startDate),
                new Date(row.original.endDate),
              )}
            </ColumnText>
          )
        },
      }),
      columnHelper.accessor('channel', {
        header: 'Channel',
        cell: ({ getValue }) => {
          return <ColumnText>{getValue()}</ColumnText>
        },
      }),
      columnHelper.accessor('funnelCampaign', {
        header: 'Funnel',
        cell: ({ getValue }) => {
          return <ColumnText>{getValue()}</ColumnText>
        },
      }),
      columnHelper.accessor('targetVariable', {
        header: 'Target KPI',
        cell: ({ getValue }) => {
          return (
            <ColumnText>
              {normalizedMetrics[getValue()]?.label ?? getValue()}
            </ColumnText>
          )
        },
        sortingFn: (a, b) => {
          return normalizedMetrics[
            a.original.targetVariable
          ]?.label.localeCompare(
            normalizedMetrics[b.original.targetVariable]?.label,
          )
        },
      }),
      columnHelper.accessor('spend', {
        header: `Spend (${currency})`,
        cell: ({ getValue }) => {
          const value = getValue()

          return (
            <ColumnText>
              {!value
                ? '-'
                : formatMetric(METRIC_FORMAT.INTEGER, value, currency)}
            </ColumnText>
          )
        },
      }),
      columnHelper.accessor('liftPct', {
        header: 'Lift %',
        cell: ({ getValue }) => {
          const value = getValue()

          return (
            <ColumnText>
              {!value
                ? '-'
                : formatMetric(METRIC_FORMAT.PERCENT, value, currency)}
            </ColumnText>
          )
        },
      }),
      columnHelper.accessor('liftAmount', {
        header: 'Lift amount',
        cell: ({ getValue }) => {
          const value = getValue()

          return (
            <ColumnText>
              {!value
                ? '-'
                : formatMetric(METRIC_FORMAT.INTEGER, value, currency)}
            </ColumnText>
          )
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        cell: ({ row }) => {
          return (
            <Box
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              <ExperimentContextMenu experiment={row.original} />
            </Box>
          )
        },
      }),
    ]
  }, [normalizedMetrics, currency])
}
