import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  SimpleGrid,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { addDays, format } from 'date-fns'
import { SettingsDatePicker } from 'features/settings/views/MarketingCostsView/AddMarketingCostSidebar/SettingsDatePicker'
import { useChannelGroups } from 'graphql/useChannelGroups'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { defaultCurrencyOptions } from '../../shared/consts'
import { type DefaultCurrency } from '../../shared/types'
import { costTypes, dateFormat, startOfToday } from '../consts'
import { useCreateMarketingCostManualInput } from '../graphql/useCreateMarketingCostManualInput'
import { CampaignMarketingCost } from './CampaignMarketingCost'
import { MarketingCostCombobox } from './MarketingCostCombobox'
import { type ValidationSchema, validationSchema } from './schemas'

interface Props {
  isOpen: boolean
  onClose: () => void
  frontendId: string
}

export const AddMarketingCostSidebar = ({
  frontendId,
  isOpen,
  onClose,
}: Props) => {
  const { channels, query: channelsQuery } = useChannels()
  const {
    channelGroups,
    channelsPerChannelGroups,
    query: channelGroupsQuery,
  } = useChannelGroups()
  const { countries, query: countryQuery } = useCountries()

  const { currency: initialCurrency } = useMerchantInfo()
  const [addCost] = useCreateMarketingCostManualInput()

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      value: undefined,
      channel: undefined,
      channelGroup: undefined,
      campaign: undefined,
      country: undefined,
      costType: undefined,
      currency: initialCurrency as DefaultCurrency,
      startDate: startOfToday,
      endDate: null,
    },
  })

  const {
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = methods

  const {
    campaign,
    channel,
    channelGroup,
    costType,
    country,
    currency,
    startDate,
    endDate,
  } = getValues()

  // Needs to be watched to re-render on changes for the end date to be aware and update once start date passes it
  watch('startDate')
  watch('endDate')

  const channelOptions = !channelGroup
    ? channels
    : channelsPerChannelGroups[channelGroup ?? '']

  const close = () => {
    reset()
    onClose()
  }

  const onSubmit: SubmitHandler<ValidationSchema> = ({
    value,
    channel,
    channelGroup,
    campaign,
    country,
    costType,
    currency,
    startDate,
    endDate,
  }) => {
    addCost({
      variables: {
        params: {
          frontendId,
          dimensions: {
            channel,
            channelGroup,
            campaign,
            country,
          },
          calculationType: costType,
          startDate: format(startDate, dateFormat),
          endDate: endDate ? format(endDate, dateFormat) : undefined,
          amount: String(value ?? 0),
          currency,
        },
      },
    })
    close()
  }

  const updateDropdown = (field: keyof ValidationSchema, id: string) => {
    setValue(field, id, { shouldValidate: true })
  }

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={close} size="lg">
      <DrawerOverlay />
      <Form<ValidationSchema> methods={methods} onSubmit={onSubmit}>
        <DrawerContent position="relative" overflowY="auto">
          <DrawerHeader>
            Add marketing costs
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody>
            <Typography fontSize="xs">
              <Typography as="span" fontWeight={700}>
                Dimensions{' '}
              </Typography>
              (Input at least 1)
            </Typography>

            <SimpleGrid mt={4} columns={2} spacing={4}>
              <MarketingCostCombobox
                dimensionId={'country'}
                options={countries}
                error={errors.country}
                selected={country}
                isLoading={countryQuery.loading}
                updateDropdown={updateDropdown}
              />

              <CampaignMarketingCost
                selected={campaign}
                error={errors.campaign}
                updateDropdown={(option) => updateDropdown('campaign', option)}
              />

              <MarketingCostCombobox
                dimensionId={'channelGroup'}
                options={channelGroups}
                error={errors.channelGroup}
                selected={channelGroup}
                isLoading={channelGroupsQuery.loading}
                updateDropdown={updateDropdown}
              />

              <MarketingCostCombobox
                dimensionId={'channel'}
                options={channelOptions}
                error={errors.channel}
                selected={channel}
                isLoading={channelsQuery.loading}
                updateDropdown={updateDropdown}
              />

              <Divider my={6} gridColumn="span 2" />

              <Dropdown
                options={costTypes}
                value={costType}
                label="Cost type"
                error={errors.costType}
                placeholder="Select cost type"
                callback={(option) =>
                  updateDropdown('costType', option.id.toString())
                }
              />
              <Box />

              <Input
                name="value"
                label="Value"
                type="number"
                min="0"
                error={errors.value}
              />
              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                label="Currency"
                options={defaultCurrencyOptions}
                selected={currency}
                error={errors.currency}
                placeholder="Select currency"
                setSelected={(option) => updateDropdown('currency', option)}
                isFullHeight
              />

              <SettingsDatePicker
                initialValue={startDate}
                updateDate={(date) => {
                  setValue('startDate', date)

                  // If the new start date overlaps the current end date
                  // This makes sure end date cant be end up being before start date
                  if (endDate && date > endDate) {
                    setValue('endDate', addDays(date, 1))
                  }
                }}
                label="Start date"
                error={errors.startDate}
              />

              <SettingsDatePicker
                initialValue={endDate}
                updateDate={(date) => setValue('endDate', date)}
                isEndDate
                label="End date"
                error={errors.endDate}
                minDate={addDays(startDate, 1)}
              />
            </SimpleGrid>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" mode="dark">
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Form>
    </Drawer>
  )
}
