import { Badge as ChakraBadge, type BadgeProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ children, colorScheme, size = 'sm', ...rest }, ref) => {
    if (!children) return null

    return (
      <ChakraBadge ref={ref} colorScheme={colorScheme} size={size} {...rest}>
        {children}
      </ChakraBadge>
    )
  },
)

Badge.displayName = 'Badge'

export default Badge
