import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { MultiCheckboxSelect } from 'components/MultiCheckboxSelect'
import type { SelectedItem } from 'components/MultiCheckboxSelect/types'
import { Typography } from 'components/Typography'
import { useDimensionsState } from 'features/reports/hooks/useDimensionsState'
import {
  useMetricsState,
  useSetMetricsState,
} from 'features/reports/hooks/useMetricsState'
import {
  getValidMetricProcessors,
  isValidMetric,
} from 'features/reports/utils/utils'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedAttributionModels } from 'graphql/statistics/useAttributionModels'
import {
  getMetricKeyWithoutAttribution,
  isAttributedMetric,
  useNormalizedMetrics,
  type AttributedMetric,
} from 'graphql/statistics/useMetrics'
import { isEqual, uniqBy } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'

type AttributionSectionProps = {
  isOpen: boolean
  onClose: () => void
}

export const AttributionModal = ({
  isOpen,
  onClose,
}: AttributionSectionProps) => {
  const [draftItems, setDraftItems] = useState<SelectedItem[]>([])
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const attributionColumns = Object.values(normalizedAttributionModels)
  const { metrics } = useMetricsState()
  const normalizedMetrics = useNormalizedMetrics()
  const { dimensions: selectedDimensions } = useDimensionsState()
  const validMetricProcessors = getValidMetricProcessors(selectedDimensions)

  const [trackEvent] = useTrackEvent()
  const setMetrics = useSetMetricsState()
  const selectedAttributions = useMemo(
    () => metrics.filter(isAttributedMetric),
    [metrics],
  )
  const attributionMetricRows = useMemo(
    () =>
      uniqBy(selectedAttributions, (val) =>
        getMetricKeyWithoutAttribution(val),
      ).map((metric) => {
        const keyWithoutAttribution = getMetricKeyWithoutAttribution(metric)

        return {
          id: keyWithoutAttribution,
          label: metric.label,
          disabledColumns: attributionColumns
            .filter(({ id }) => {
              const attributedKey = `${keyWithoutAttribution}:${id}`
              const metric = normalizedMetrics[attributedKey]

              return !metric || !isValidMetric(metric, validMetricProcessors)
            })
            .map(({ id }) => id),
        }
      }),
    [
      attributionColumns,
      normalizedMetrics,
      selectedAttributions,
      validMetricProcessors,
    ],
  )

  // Check if any row is empty
  const emptyRows =
    new Set(draftItems.map((item) => item.rowId).filter((item) => item)).size <
    attributionMetricRows.length

  const noData = attributionMetricRows.length === 0

  // Set initial selected items per row
  useEffect(() => {
    if (attributionColumns.length <= 0 || attributionMetricRows.length <= 0) {
      setDraftItems([])
    } else {
      setDraftItems(
        selectedAttributions.map((attribution) => ({
          rowId: attribution.groupKey ?? attribution.key,
          columnId: attribution.attributionId ?? '',
        })),
      )
    }
  }, [
    selectedAttributions,
    attributionColumns.length,
    attributionMetricRows.length,
  ])

  const onApply = () => {
    onClose()

    // if the new selectedAttributions is invalid
    if (emptyRows) {
      setDraftItems(
        selectedAttributions.map((attribution) => {
          return {
            rowId: attribution.groupKey ?? attribution.key,
            columnId: attribution.attributionId ?? '',
          }
        }),
      )

      return
    }

    const selected = draftItems
      .map((item) => {
        return `${item.rowId}:${item.columnId}`
      })
      .sort()

    const selectedAttributionsIds = selectedAttributions
      .map((m) => m.key)
      .sort()

    if (isEqual(selectedAttributionsIds, selected)) {
      return
    }

    const updatedMetrics = [
      ...metrics
        .filter((metric) => !(metric as AttributedMetric).attributionId)
        .map((metric) => metric.key),
      ...selected,
    ]

    setMetrics(updatedMetrics)

    trackEvent({
      eventName: 'Report Attribution Changed',
      eventProperties: {
        oldAttributionIds: selectedAttributions.map(
          (attribution) => attribution.id,
        ),
        newAttributionIds: selectedAttributionsIds,
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onApply} size="small">
      <ModalOverlay />
      <ModalContent w="unset">
        <ModalHeader>Attribution</ModalHeader>
        <ModalCloseButton size="sm" onClick={onClose} />
        <ModalBody maxH="90vh" overflow="auto">
          {noData ? (
            <Typography p={2} fontSize="sm" fontWeight={500}>
              Attribution is not applicable for any of the selected metrics
            </Typography>
          ) : (
            <>
              <MultiCheckboxSelect
                rows={attributionMetricRows}
                columns={attributionColumns}
                selectedItems={draftItems}
                setSelectedItems={setDraftItems}
              />

              {emptyRows && (
                <Box mt={6}>
                  <Alert
                    content="Select at least one attribution model per row"
                    status="error"
                  />
                </Box>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
