import { Box, Flex } from '@chakra-ui/react'
import { Checkbox } from 'components/Checkbox'
import { Typography } from 'components/Typography'
import type { TableItemColumn, TableItemRow, SelectedItem } from './types'

interface Props {
  columns: TableItemColumn[]
  row: TableItemRow
  selectedItems: SelectedItem[]
  toggleSelectedItem: (rowId: string, columnId: string) => void
}

export const TableBodyRow = ({
  columns,
  row,
  selectedItems,
  toggleSelectedItem,
}: Props) => {
  const isChecked = (columnId: string) => {
    return (
      selectedItems.find(
        (s) => s.rowId === row.id && s.columnId === columnId,
      ) !== undefined
    )
  }
  const isDisabled = (columnId: string) => {
    return row.disabledColumns.includes(columnId)
  }

  return (
    <Box
      as="tr"
      borderBottom="solid 1px"
      borderColor="grey.200"
      _last={{ border: 'none' }}
    >
      <Box as="td" py={1.5} px={2} borderX="none">
        <Typography fontSize="xs">{row.label}</Typography>
      </Box>
      {columns.map((column) => (
        <td key={column.id}>
          <Flex justifyContent="center">
            <Checkbox
              size="sm"
              onChange={() => toggleSelectedItem(row.id, column.id)}
              isChecked={isChecked(column.id)}
              isDisabled={isDisabled(column.id)}
            />
          </Flex>
        </td>
      ))}
    </Box>
  )
}
