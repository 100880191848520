import { Dropdown } from 'components/Dropdown'
import { subDays } from 'date-fns'
import { useDimensionValues } from 'graphql/statistics/useDimensionValues'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { type FieldError } from 'react-hook-form'
import { toISODateRange } from 'utils/dateRange'

interface FunnelDropdownProps {
  funnelCampaign: string
  error: FieldError | undefined
  onChange: (option: string) => void
}

export const FunnelDropdown: React.FC<FunnelDropdownProps> = ({
  funnelCampaign,
  error,
  onChange,
}) => {
  const { frontendIds = [], timezone } = useMerchantInfo()

  // need to memo to avoid re-fetching when the new Date() changes on each render
  const [startDate, endDate] = useMemo(() => {
    const now = new Date()
    const nowMinus30Days = subDays(now, 30)

    return toISODateRange([nowMinus30Days, now], timezone)
  }, [timezone])

  const { query, dimensionValues } = useDimensionValues({
    variables: {
      dimensionValuesParams: {
        frontendIds,
        startDate: startDate as string,
        endDate: endDate as string,
        dimension: 'funnelCampaign',
      },
    },
    skip: !frontendIds.length,
  })

  return (
    <Dropdown
      options={dimensionValues?.map(({ value, formattedValue }) => ({
        id: String(value),
        name: formattedValue,
      }))}
      isLoading={query.loading}
      value={funnelCampaign}
      label="Funnel"
      error={error}
      placeholder="Select Funnel"
      callback={(option) => onChange(option.id)}
    />
  )
}
