import { type FC } from 'react'
import { colorTheme } from 'ui/theme/colors'

interface TooltipSectionLabelProps {
  label: string | undefined
}

export const TooltipSectionLabel: FC<TooltipSectionLabelProps> = ({
  label,
}) => {
  if (!label) return null

  return (
    <div
      style={{
        marginBottom: '4px',
        color: colorTheme.grey[700],
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '14px',
      }}
    >
      {label}
    </div>
  )
}
