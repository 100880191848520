import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type ExperimentSummary } from './fragments'

export const EXPERIMENTS_QUERY = graphql(/* GraphQL */ `
  query Experiments {
    viewer {
      id
      merchant {
        id
        experiments {
          ...ExperimentSummaryFields
        }
      }
    }
  }
`)

export const useExperiments = () => {
  const { data, ...query } = useQuery(EXPERIMENTS_QUERY)

  return {
    query,
    experiments: (data?.viewer?.merchant.experiments ??
      []) as ExperimentSummary[],
  }
}
