import { type Metric as GraphMetric } from 'generated/graphql/graphql'
import { atom, useAtomValue } from 'jotai'
import { type MetricFormat } from './types'
import { type AttributionId } from './useAttributionModels'

type MetricGroupId =
  | 'cost'
  | 'order'
  | 'customer'
  | 'session'
  | 'pageViews'
  | 'product'

type EnhancedBaseMetricFragment = {
  key: string
  groupKey: string
  groupId: MetricGroupId
  format: MetricFormat
  attributionVariants?: AttributionVariant[]
}

export type BaseMetric = Omit<GraphMetric, keyof EnhancedBaseMetricFragment> &
  EnhancedBaseMetricFragment

export type AttributedMetric = BaseMetric & {
  attributionId: AttributionId
}

export type AttributionVariant = {
  modelId: AttributionId
  dependencies: string[]
}

export type Metric = BaseMetric | AttributedMetric

export type NormalizedMetrics = Record<string, Metric>

// Type predicate for discriminating Metric
export const isAttributedMetric = (
  metric: Metric,
): metric is AttributedMetric =>
  !!metric.attributionVariants && 'attributionId' in metric

export const getMetricKeyWithoutAttribution = (
  { groupKey, key }: Metric | undefined = {} as Metric,
) => groupKey ?? key ?? ''

const enhanceMetrics = (metrics: GraphMetric[]): BaseMetric[] =>
  metrics
    .filter((metric) => !metric.isHidden)
    .map((metric) => {
      const groupId = metric.groupId as MetricGroupId

      return {
        ...metric,
        groupId,
        format: metric.format as MetricFormat,
        key: `${groupId}:${metric.id}`,
        groupKey: `${groupId}:${metric.id}`,
        attributionVariants: metric.attributionVariants as
          | AttributionVariant[]
          | undefined,
      }
    })

const generateAttributionMetrics = (metrics: BaseMetric[]): Metric[] => {
  const attributedMetrics: Metric[] = []

  metrics.forEach((metric) => {
    attributedMetrics.push(metric)
    if (metric.attributionVariants) {
      metric.attributionVariants.forEach(
        ({ modelId: attributionId, dependencies }) => {
          attributedMetrics.push({
            ...metric,
            key: `${metric.key}:${attributionId}`,
            attributionId,
            dependencies,
          })
        },
      )
    }
  })

  return attributedMetrics
}

export const metricsQueryAtom = atom<GraphMetric[] | null>(null)

export const normalizedMetricsAtom = atom<NormalizedMetrics>((get) => {
  const metrics = get(metricsQueryAtom)

  const enhancedMetrics = enhanceMetrics(metrics ?? [])
  const attributedMetrics = generateAttributionMetrics(enhancedMetrics)

  return attributedMetrics.reduce<NormalizedMetrics>((acc, next) => {
    acc[next.key] = next

    return acc
  }, {} as NormalizedMetrics)
})

export const useNormalizedMetrics = (): NormalizedMetrics => {
  return useAtomValue(normalizedMetricsAtom)
}
