import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const UPDATE_ACTIVE_SETUP_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateActiveSetup($params: UpdateActiveSetupInput!) {
    updateActiveSetup(params: $params) {
      ...ExperimentFields
    }
  }
`)

export const useUpdateActiveSetup = () => {
  return useMutation(UPDATE_ACTIVE_SETUP_MUTATION)
}
