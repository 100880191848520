import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { REPORT_PAGES } from 'features/reports/routePages'
import { type Dispatch, type FC, type SetStateAction } from 'react'
import { CopyReportURLMenuItem } from './CopyReportURLMenuItem'
import { DeleteReportMenuItem } from './DeleteReportMenuItem'
import { DuplicateReportMenuItem } from './DuplicateReportMenuItem'

interface Props {
  report: ReportState
  userId: string | undefined
  isEditable?: boolean
  setIsEditing?: Dispatch<SetStateAction<boolean>>
}

export const ReportContextualMenu: FC<Props> = ({
  report,
  userId,
  isEditable = false,
  setIsEditing,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const canEditReport = useCanEditReport(report)

  if (!report) return null
  const isOwnUser = userId === report.owner?.id
  // Delete is more restrictive than edit so we need a different check
  const isDeleteAllowed = report.id && isOwnUser

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy size="sm">
      <MenuButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onOpen()
        }}
        as={IconButton}
        size="sm"
        aria-label="Options"
        icon={
          <Icon
            name="HorizontalMenuIcon"
            tooltipLabel="More actions"
            size="small"
          />
        }
        variant="ghost"
      />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList
          onClick={(e) => e.stopPropagation()}
          zIndex="dropdown" // zIndex is set to dropdown to ensure the menu is below the delete report modal
        >
          {isEditable && report.id && canEditReport && (
            <MenuItem
              onClick={() => setIsEditing?.((prev) => !prev)}
              icon={<Icon name="EditIcon" size="small" />}
            >
              Edit report
            </MenuItem>
          )}
          <DuplicateReportMenuItem report={report} />

          <MenuDivider />

          <CopyReportURLMenuItem reportId={report.id} />
          <MenuItem
            onClick={() => {
              window.open(REPORT_PAGES.REPORT_BY_ID(report.id), '_blank')
            }}
            icon={<Icon name="LinkExternalIcon" size="small" />}
          >
            Open in new tab
          </MenuItem>

          {isDeleteAllowed && (
            <>
              <MenuDivider />
              <DeleteReportMenuItem reportId={report.id} />
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  )
}
