import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_EXPERIMENT_MUTATION = graphql(/* GraphQL */ `
  mutation CreateExperiment($params: CreateExperimentInput!) {
    createExperiment(params: $params) {
      config {
        id
        name
      }
      __typename
    }
  }
`)

export const useCreateExperiment = () => {
  return useMutation(CREATE_EXPERIMENT_MUTATION)
}
