import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { Typography } from 'components/Typography'
import { useState, useRef, useEffect } from 'react'
import { AnimatedEllipsis } from './components/AnimatedEllipsis'
import { BotIcon } from './components/BotIcon'
import { BotMessage } from './components/BotMessage'
import { ExampleQuestions } from './components/ExampleQuestions'
import { MessageInput } from './components/MessageInput'
import { UserMessage } from './components/UserMessage'
import { botWelcomeMessage } from './consts'
import { useCreateConversation } from './graphql/useCreateConversation'
import { useCreateConversationMessage } from './graphql/useCreateConversationMessage'
import { useGetConversationById } from './graphql/useGetConversationById'
import { useCopilotIsOpen } from './state'
import { sendOptimisticMessage } from './utils/sendOptimisticMessage'

const CopilotSidebar = () => {
  const [isOpen, setIsOpen] = useCopilotIsOpen()
  const [conversationId, setConversationId] = useState<string | null>(null)
  const [message, setMessage] = useState('')
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const [createConversation, createConversationState] = useCreateConversation()
  const [createConversationMessage, createConversationMessageState] =
    useCreateConversationMessage()

  const isLoading =
    createConversationState.loading || createConversationMessageState.loading

  const [conversation] = useGetConversationById(conversationId)

  const isEmptyConversation =
    !conversation || conversation.messages.length === 0

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const { user } = useUser()

  useEffect(() => {
    scrollToBottom()
  }, [conversation?.messages?.length])

  const sendMessage = (message: string) => {
    sendOptimisticMessage({
      conversationId,
      message,
      createConversation,
      createConversationMessage,
      onSetConversationId: setConversationId,
      onSetMessage: setMessage,
      viewerId: user?.publicMetadata.dema_id ?? '',
    })
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => setIsOpen(false)}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          Dema copilot
          <DrawerCloseButton />
        </DrawerHeader>

        <DrawerBody>
          <Stack gap={4}>
            <BotMessage message={botWelcomeMessage} />

            {isEmptyConversation ? (
              <ExampleQuestions
                onQuestionClick={(question) => {
                  sendMessage(question)
                }}
              />
            ) : (
              conversation?.messages.map((message) =>
                message.author.role === 'User' ? (
                  <UserMessage message={message} key={message.id} />
                ) : (
                  <BotMessage message={message} key={message.id} />
                ),
              )
            )}

            {isLoading && (
              <Flex gap={2}>
                <BotIcon />
                <Typography
                  fontSize="sm"
                  lineHeight={5}
                  color="gray.900"
                  fontWeight={400}
                  pt={2}
                >
                  <AnimatedEllipsis />
                </Typography>
              </Flex>
            )}
          </Stack>
          <div ref={messagesEndRef} />
        </DrawerBody>

        <DrawerFooter>
          <MessageInput
            sendMessage={sendMessage}
            setConversationId={setConversationId}
            isLoading={isLoading}
            message={message}
            setMessage={setMessage}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default CopilotSidebar
