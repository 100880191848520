import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useUpdateActiveSetup } from 'features/geoLift/graphql/useUpdateActiveSetup'
import type React from 'react'

interface FinalizeExperimentButtonProps {
  experimentId: string
  selectedSetupId: string
}

export const FinalizeExperimentButton: React.FC<
  FinalizeExperimentButtonProps
> = ({ experimentId, selectedSetupId }) => {
  const [updateActiveSetup, updateActiveSetupState] = useUpdateActiveSetup()

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="sm" onClick={() => onOpen()}>
        Finalize experiment
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Finalize experiment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            When the experiment is finalized it will no longer be possible to
            change any of the experiment parameters.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              isLoading={updateActiveSetupState.loading}
              onClick={() => {
                updateActiveSetup({
                  variables: {
                    params: {
                      id: experimentId,
                      activeExperimentSetupId: selectedSetupId,
                    },
                  },
                  onCompleted: () => {
                    onClose()
                  },
                })
              }}
            >
              Finalize experiment
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
