import { useDisclosure, useToast } from '@chakra-ui/react'
import { type CreateSegmentationSetInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { set } from 'lodash-es'
import cloneDeep from 'lodash-es/cloneDeep'
import { type ReactElement } from 'react'
import { useCreateSegmentationSet } from '../graphql/useCreateSegmentationSet'
import { SEGMENTATION_SETS_QUERY } from '../graphql/useSegmentationSets'
import { SegmentationSetPicker } from './SegmentationSetPicker'

interface AddSegmentationSetModalProps {
  modalButton: ReactElement
  onSegmentationSetCreated: (segmentationSet: {
    name: string
    id: string
  }) => void
}

export const AddSegmentationSetModal: React.FC<
  AddSegmentationSetModalProps
> = ({ modalButton, onSegmentationSetCreated }) => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const [createSegmentationSet, createSegmentationSetMutationMutation] =
    useCreateSegmentationSet()
  const modalDisclosure = useDisclosure()

  const onApply = (segmentationSet: CreateSegmentationSetInput) => {
    if (!segmentationSet.name) return

    createSegmentationSet({
      variables: {
        params: {
          name: segmentationSet.name,
          iconColor: segmentationSet.iconColor,
          iconName: segmentationSet.iconName,
          dynamicDate: segmentationSet.dynamicDate,
          labelColumnName: segmentationSet.labelColumnName,
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery({ query: SEGMENTATION_SETS_QUERY }, (queryData) => {
          if (!queryData?.viewer?.merchant.segmentationSets) return queryData

          const queryDataClone = cloneDeep(queryData)

          set(queryDataClone, 'viewer.merchant.segmentationSets', [
            ...queryData.viewer.merchant.segmentationSets,
            data.createSegmentationSet,
          ])

          return queryDataClone
        })
      },
      onCompleted: ({ createSegmentationSet }) => {
        trackEvent({
          eventName: 'Segmentations Segmentation Set Created',
          eventProperties: {
            id: createSegmentationSet.id,
            name: createSegmentationSet.name,
            iconName: createSegmentationSet.iconName,
            iconColor: createSegmentationSet.iconColor,
            labelColumnName: createSegmentationSet.labelColumnName,
          },
        })

        modalDisclosure.onClose()
        onSegmentationSetCreated({
          name: createSegmentationSet.name,
          id: createSegmentationSet.id,
        })
      },
      onError: () => {
        toast({
          description: 'Could not create product set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <SegmentationSetPicker
      currentSegmentationSet={undefined}
      modalButton={modalButton}
      onApply={onApply}
      closeOnApply={false}
      disclosure={modalDisclosure}
      isLoading={createSegmentationSetMutationMutation.loading}
    />
  )
}
