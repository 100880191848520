import { gql, useMutation } from '@apollo/client'

const DELETE_EXPERIMENT = gql`
  mutation DeleteExperiment($id: ID!) {
    deleteExperiment(id: $id) {
      id
      __typename
    }
  }
`

export const useDeleteExperiment = () => {
  const [deleteExperiment, { loading }] = useMutation(DELETE_EXPERIMENT)

  return {
    deleteExperiment,
    loading,
  }
}
