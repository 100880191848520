import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import { FilterOperator } from 'components/Filters/types'
import { MAX_NUMBER_OF_COLUMNS } from '../consts'
import { type DashboardState } from '../hooks/useDashboardState'
import { DASHBOARD_ID } from './consts'
import { GLOBAL_FILTER } from './globalFilters'

const googleFilter = {
  channel: [
    {
      comparisonId: FilterOperator.isAnyOf,
      value: ['google'],
    },
  ],
  channelGroup: [
    {
      comparisonId: FilterOperator.isAnyOf,
      value: ['sem'],
    },
  ],
}

const facebookFilter = {
  channel: [
    {
      comparisonId: FilterOperator.isAnyOf,
      value: ['facebook'],
    },
  ],
  channelGroup: [
    {
      comparisonId: FilterOperator.isAnyOf,
      value: ['social_ppc'],
    },
  ],
}

export const marketingDashboard = {
  id: DASHBOARD_ID.MARKETING,
  label: 'Marketing',
  globalFilterIds: [
    GLOBAL_FILTER.STOREFRONT,
    GLOBAL_FILTER.MARKET,
    GLOBAL_FILTER.FUNNEL_CAMPAIGN,
  ],
  dynamicDateRanges: [
    DYNAMIC_DATE_ID.THIS_WEEK_TO_DATE,
    DYNAMIC_DATE_ID.THIS_MONTH_TO_DATE,
    DYNAMIC_DATE_ID.THIS_YEAR_TO_DATE,
    DYNAMIC_DATE_ID.YESTERDAY,
    DYNAMIC_DATE_ID.LAST_7_DAYS,
    DYNAMIC_DATE_ID.LAST_14_DAYS,
    DYNAMIC_DATE_ID.LAST_28_DAYS,
    DYNAMIC_DATE_ID.LAST_WEEK,
    DYNAMIC_DATE_ID.LAST_MONTH,
  ],
  compareDynamicDateRanges: [
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_MONTH,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR,
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_YEAR_MATCHING_WEEKDAYS,
    COMPARE_DYNAMIC_DATE_ID.LAST_BLACK_FRIDAY,
  ],
  defaultDateRange: DYNAMIC_DATE_ID.LAST_7_DAYS,
  widgets: [
    {
      id: 'marketing-metric-group',
      type: 'metricGroup',
      groupSize: 5,
      gridSpan: MAX_NUMBER_OF_COLUMNS,
      metrics: [
        'cost:total',
        'order:netGrossMargin3:dema',
        'cost:roas:dema',
        'cost:epRoas:dema',
        'cost:customerAcquisitionCost:dema',
      ],
      size: 'lg',
      dimensions: [],
      allowHourlyData: false,
    },
    {
      id: 'marketing-multiMetricChart-line-overview',
      type: 'multiMetricChart',
      title: 'Overall marketing performance',
      gridSpan: 5,
      dimensions: [],
      showComparison: false,
      selectedMetrics: ['cost:total', 'cost:epRoas:dema'],
      chartType: 'line',
      metrics: [
        'ads:impressions',
        'ads:clicks',
        'ads:ctr',
        'order:count:dema',
        'order:count:ad',
        'session:conversionRate:dema',
        'session:conversionRate:ad',
        'order:total:dema',
        'order:total:ad',
        'order:netGrossProfit3:dema',
        'order:netGrossProfit3:ad',
        'cost:total',
        'cost:roas:dema',
        'cost:roas:ad',
        'cost:epRoas:dema',
        'cost:epRoas:ad',
        'cost:ncepRoas:dema',
        'cost:costPerClick',
        'cost:costPerMille',
        'cost:costOfSales:dema',
      ],
      allowHourlyData: false,
    },
    {
      id: 'marketing-multiMetricChart-markets-overview',
      type: 'multiMetricChart',
      title: 'Markets overview - all channels',
      gridSpan: 7,
      dimensions: ['market'],
      showComparison: false,
      selectedMetrics: ['cost:total', 'cost:ncepRoas:dema'],
      chartType: 'column',
      metrics: [
        'ads:impressions',
        'ads:clicks',
        'ads:ctr',
        'order:count:dema',
        'order:count:ad',
        'session:conversionRate:dema',
        'session:conversionRate:ad',
        'order:total:dema',
        'order:total:ad',
        'order:netGrossProfit3:dema',
        'order:netGrossProfit3:ad',
        'cost:total',
        'cost:roas:dema',
        'cost:roas:ad',
        'cost:epRoas:dema',
        'cost:epRoas:ad',
        'cost:ncepRoas:dema',
        'cost:costPerClick',
        'cost:costPerMille',
        'cost:costOfSales:dema',
      ],
      allowHourlyData: false,
    },
    {
      id: 'marketing-donut',
      title: 'Marketing channels',
      type: 'donut',
      gridSpan: MAX_NUMBER_OF_COLUMNS,
      metrics: [
        'cost:total',
        'session:count',
        'order:count:dema',
        'order:total:dema',
      ],
      dimensions: ['channel'],
      filter: {
        channel: [
          {
            comparisonId: FilterOperator.isAnyOf,
            value: ['google', 'facebook'],
          },
        ],
      },
      hasAttribution: true,
      allowHourlyData: false,
    },
    {
      id: 'marketing-market-group-google',
      type: 'marketingGroup',
      gridSpan: MAX_NUMBER_OF_COLUMNS,
      title: 'Google Ads',
      subtitle: '(as reported by Google)',
      logo: '/images/GoogleAds.svg',
      widgets: [
        {
          id: 'marketing-metricGroup-google',
          type: 'metricGroup',
          gridSpan: 4,
          metrics: [
            'ads:impressions',
            'cost:total',
            'ads:clicks',
            'order:total:ad',
            'ads:ctr',
            'order:netGrossProfit3:ad',
            'order:count:ad',
            'cost:roas:ad',
            'session:conversionRate:ad',
            'cost:epRoas:ad',
            'cost:costPerClick',
            'cost:costPerMille',
          ],
          size: 'sm',
          dimensions: [],
          filter: googleFilter,
          allowHourlyData: false,
        },
        {
          id: 'marketing-multiMetricChart-google',
          type: 'multiMetricChart',
          title: 'Google Ads performance',
          gridSpan: 8,
          dimensions: [],
          showComparison: false,
          selectedMetrics: ['cost:total', 'order:netGrossProfit3:ad'],
          chartType: 'line',
          metrics: [
            'ads:impressions',
            'ads:clicks',
            'ads:ctr',
            'cost:total',
            'order:total:ad',
            'order:netGrossProfit3:ad',
            'order:count:ad',
            'cost:roas:ad',
            'session:conversionRate:ad',
            'cost:epRoas:ad',
            'cost:costPerClick',
            'cost:costPerMille',
            'cost:costOfSales:dema',
          ],
          filter: googleFilter,
          allowHourlyData: false,
        },
        {
          id: 'marketing-multiMetricChart-markets-google',
          type: 'multiMetricChart',
          title: 'Markets overview - Google Ads',
          gridSpan: MAX_NUMBER_OF_COLUMNS,
          dimensions: ['market'],
          showComparison: false,
          selectedMetrics: ['cost:total', 'cost:ncepRoas:dema'],
          chartType: 'column',
          metrics: [
            'ads:impressions',
            'ads:clicks',
            'ads:ctr',
            'order:total:ad',
            'order:netGrossProfit3:ad',
            'order:count:ad',
            'cost:roas:ad',
            'session:conversionRate:ad',
            'cost:total',
            'cost:ncepRoas:dema',
            'cost:epRoas:ad',
            'cost:costPerClick',
            'cost:costPerMille',
            'cost:costOfSales:dema',
          ],
          filter: googleFilter,
          allowHourlyData: false,
        },
      ],
    },
    {
      id: 'marketing-market-group-meta',
      type: 'marketingGroup',
      gridSpan: MAX_NUMBER_OF_COLUMNS,
      title: 'Meta Ads',
      subtitle: '(as reported by Meta)',
      logo: '/images/Meta.svg',
      widgets: [
        {
          id: 'marketing-metricGroup-meta',
          type: 'metricGroup',
          gridSpan: 4,
          metrics: [
            'ads:impressions',
            'cost:total',
            'ads:clicks',
            'order:total:ad',
            'ads:ctr',
            'order:netGrossProfit3:ad',
            'order:count:ad',
            'cost:roas:ad',
            'session:conversionRate:ad',
            'cost:epRoas:ad',
            'cost:costPerClick',
            'cost:costPerMille',
          ],
          size: 'sm',
          dimensions: [],
          filter: facebookFilter,
          allowHourlyData: false,
        },
        {
          id: 'marketing-multiMetricChart-meta',
          type: 'multiMetricChart',
          title: 'Meta Ads performance',
          gridSpan: 8,
          dimensions: [],
          showComparison: false,
          selectedMetrics: ['cost:total', 'cost:epRoas:ad'],
          chartType: 'line',
          metrics: [
            'ads:impressions',
            'ads:clicks',
            'ads:ctr',
            'cost:total',
            'order:total:ad',
            'order:netGrossProfit3:ad',
            'order:count:ad',
            'cost:roas:ad',
            'session:conversionRate:ad',
            'cost:epRoas:ad',
            'cost:costPerClick',
            'cost:costPerMille',
            'cost:costOfSales:dema',
          ],
          filter: facebookFilter,
          allowHourlyData: false,
        },
        {
          id: 'marketing-multiMetricChart-markets-meta',
          type: 'multiMetricChart',
          title: 'Markets overview - Meta Ads',
          gridSpan: MAX_NUMBER_OF_COLUMNS,
          dimensions: ['market'],
          showComparison: false,
          selectedMetrics: ['cost:total', 'cost:ncepRoas:dema'],
          chartType: 'column',
          metrics: [
            'ads:impressions',
            'ads:clicks',
            'ads:ctr',
            'order:total:ad',
            'order:netGrossProfit3:ad',
            'order:count:ad',
            'cost:roas:ad',
            'session:conversionRate:ad',
            'cost:total',
            'cost:ncepRoas:dema',
            'cost:epRoas:ad',
            'cost:costPerClick',
            'cost:costPerMille',
            'cost:costOfSales:dema',
          ],
          filter: facebookFilter,
          allowHourlyData: false,
        },
      ],
    },
  ],
} satisfies DashboardState['dashboards'][number]
